import axios, { AxiosResponse } from 'axios';
import { Observation } from './MeteoDataModel';

export async function getMetedataHistory(deviceId: string, apiKey: string): Promise<Observation[]> {
    try {
        const url = `https://api.weather.com/v2/pws/observations/hourly/7day?stationId=${deviceId}&format=json&units=m&apiKey=${apiKey}&numericPrecision=decimal`;
        const response: AxiosResponse = await axios.get(url);
        const data = response.data.observations as Observation[];
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Error message: ', error.message);
        } else {
            console.error('Unexpected error: ', error);
        }
    }
}

export async function getMetedataCurrent(deviceId: string, apiKey: string): Promise<Observation[]> {
    try {
        const url = `https://api.weather.com/v2/pws/observations/current?stationId=${deviceId}&format=json&units=m&apiKey=${apiKey}&numericPrecision=decimal`;
        const response: AxiosResponse = await axios.get(url);
        const data = response.data.observations as Observation[];
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Error message: ', error.message);
        } else {
            console.error('Unexpected error: ', error);
        }
    }
}