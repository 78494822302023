import { useEffect, useState } from "react";
import DeviceStore from "../../Store/DeviceStorage";
import { getMetedataHistory } from "../../Service/Meteo/MeteoData";
import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Observation } from "../../Service/Meteo/MeteoDataModel";

function MeteorologicalData() {

    const currendDevice = DeviceStore((state) => state.currentDevice)
    const [metedata, setMeteData] = useState<Observation[]>([])

    useEffect(() => {
        getMetedataHistory(currendDevice.weatherStationID, currendDevice.weatherStationKey).then(data => {
            setMeteData(data)
        })
    }, [currendDevice])

    return (
        <>
            <h2>Temperatura</h2>
            <ResponsiveContainer width="95%" height={350}>
                <ComposedChart data={metedata} margin={{ top: 5, right: 20, left: 10, bottom: 5 }} >
                    <XAxis dataKey="obsTimeLocal" />
                    <YAxis type="number" domain={['auto', 'auto']} allowDataOverflow={true} />

                    <Tooltip />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="metric.tempHigh" name="temp maks" stroke="#FF0000" />
                    <Line type="monotone" dataKey="metric.tempLow" name="temp min" stroke="#000dff" />
                </ComposedChart>
            </ResponsiveContainer>

            <h2>Wiatr</h2>
            <ResponsiveContainer width="95%" height={350}>
                <ComposedChart data={metedata} margin={{ top: 5, right: 20, left: 10, bottom: 5 }} >
                    <XAxis dataKey="obsTimeLocal" />
                    <YAxis type="number" domain={['auto', 'auto']} allowDataOverflow={true} />

                    <Tooltip />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="metric.windspeedHigh" name="wiatr maks" stroke="#000dff" />
                </ComposedChart>
            </ResponsiveContainer>
        </>
    )
}

export default MeteorologicalData;