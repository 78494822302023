
import { useEffect, useState } from 'react'
import { Brush, CartesianGrid, ComposedChart, Line, LineChart, ResponsiveContainer, Scatter, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from 'antd';
import { DESDataModel } from '../services/DESDataModel';
import { downloadData } from 'aws-amplify/storage';
import DeviceStore from '../../../Store/DeviceStorage';
import { usePapaParse } from 'react-papaparse';
import './Graphs.css'
import { DeviceData } from '../../../API';
import { getDeviceData } from '../../../Service/Devices/DevicesService';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import MeteorologicalData from '../MeteorologicalData';

type MergeData = {
    timestamp: number;
    dyMm: number;
    count: number;
    category: string;
    dateTimeOriginal: string
}

function Graphs() {

    const { readString } = usePapaParse();

    const [minHorizontal, setMinHorizontal] = useState<number>(0)
    const [maxHorizontal, setMaxHorizontal] = useState<number>(0)
    const [minVertical, setMinVertical] = useState<number>(0)
    const [maxVertical, setMaxVertical] = useState<number>(0)

    const currendDevice = DeviceStore((state) => state.currentDevice)

    const [cameraRaw, setCameraRaw] = useState<DESDataModel[]>([])
    const [camera, setCamera] = useState<DESDataModel[]>([])
    const [aiData, setAiData] = useState<DeviceData[]>([])
    const [mergeData, setMergeData] = useState<MergeData[]>([])

    const { RangePicker } = DatePicker;
    const [dateFromPickerSetValue, setDateFromPickerSetValue] = useState<number>(-1);
    const [dateToPickerSetValue, setDateToPickerSetValue] = useState<number>(-1);
    const [rangePresetsValue, setRangePresetsValue] = useState<number>(0);


    const [time, setTime] = useState(1);
    useEffect(() => {
        getData()
    }, [time, currendDevice]);

    useEffect(() => {
        let kamera = cameraRaw.filter((data: DESDataModel) => (data.DateTimeOriginalTimeStamp > dateFromPickerSetValue && data.DateTimeOriginalTimeStamp < dateToPickerSetValue))
        // kamera = kamera.filter((data: DESDataModel) => data.dxMm < currendDevice.maxValue && data.dxMm > currendDevice.minValue)
        kamera = kamera.filter((data: DESDataModel) => data.dyMm < currendDevice.maxValue && data.dyMm > currendDevice.minValue)
        setCamera(kamera)
    }, [cameraRaw, dateFromPickerSetValue, dateToPickerSetValue]);

    useEffect(() => {
        if (currendDevice && currendDevice.cameraAI)
            getDeviceData(currendDevice.id).then((data) => {
                setAiData(data)
            })
    }, [currendDevice])

    // useEffect(() => {
    //     if (aiData.length && camera.length && currendDevice.cameraAI) {
    //         setMergeData(camera.flatMap((camera: DESDataModel) => {

    //             const aiElements = aiData.filter((item) => camera.DateTimeOriginalTimeStamp > Number(item.data_start_timestamp.substring(0, item.data_start_timestamp.length - 3)) && camera.DateTimeOriginalTimeStamp < Number(item.data_end_timestamp.substring(0, item.data_end_timestamp.length - 3))).map((item) => {
    //                 const merge: MergeData = {
    //                     dyMm: camera.dyMm,
    //                     timestamp: camera.DateTimeOriginalTimeStamp,
    //                     dateTimeOriginal: camera.DateTimeOriginal,
    //                     count: 1,
    //                     category: item.category
    //                 };
    //                 return merge;
    //             });
    //             if (aiElements.length) {

    //                 let countWeightMin = 0;
    //                 let countWeightMax = 0;

    //                 aiElements.forEach((vehicle) => {
    //                     if (vehicle.category === 'car') {
    //                         countWeightMin += 1;
    //                         countWeightMax += 3.5;
    //                     }

    //                     if (vehicle.category === 'van') {
    //                         countWeightMin += 2;
    //                         countWeightMax += 3.5;
    //                     }

    //                     if (vehicle.category === 'light') {
    //                         countWeightMin += 2.5;
    //                         countWeightMax += 3.5;
    //                     }
    //                     if (vehicle.category === 'bus') {
    //                         countWeightMin += 2;
    //                         countWeightMax += 3.5;
    //                     }
    //                     if (vehicle.category === 'caravan') {
    //                         countWeightMin += 2;
    //                         countWeightMax += 3.5;
    //                     }
    //                     if (vehicle.category === 'heavy') {
    //                         countWeightMin += 15;
    //                         countWeightMax += 25;
    //                     }
    //                     if (vehicle.category === 'truck trailer') {
    //                         countWeightMin += 20;
    //                         countWeightMax += 42;
    //                     }
    //                     if (vehicle.category === 'tractor') {
    //                         countWeightMin += 20;
    //                         countWeightMax += 42;
    //                     }
    //                 })

    //                 const merge: MergeData = {
    //                     dyMm: camera.dyMm,
    //                     timestamp: camera.DateTimeOriginalTimeStamp,
    //                     dateTimeOriginal: camera.DateTimeOriginal,
    //                     count: aiElements.length,
    //                     category: 'min: ' + countWeightMin + ' max: ' + countWeightMax
    //                 };
    //                 return merge;
    //             }

    //             const merge: MergeData = {
    //                 dyMm: camera.dyMm,
    //                 timestamp: camera.DateTimeOriginalTimeStamp,
    //                 dateTimeOriginal: camera.DateTimeOriginal,
    //                 count: null,
    //                 category: 'min: 0 max: 0'
    //             };
    //             return merge;
    //         }));
    //     }
    // }, [aiData, camera])

    async function getData() {

        try {
            if (currendDevice === null) return
            setRangePresetsValue(currendDevice.rangePreset)
            let dataFormatString = currendDevice.dateFormat

            const downloadResult = await downloadData({
                key: currendDevice.urlData
            }).result;
            const text = await downloadResult.body.text();
            readString(text, {
                header: true,
                worker: true,
                complete: (results) => {
                    const data = (results.data as DESDataModel[]).map(x => {
                        let dmYInt = (Math.round((Number(x.dyMm) + Number.EPSILON) * 10000) / 10000) * -1;
                        x.dyMm = dmYInt;
                        x.DateTimeOriginalTimeStamp = dayjs(x.DateTimeOriginal, dataFormatString).unix()
                        return x;
                    })
                    setCameraRaw(data.filter((x) => x.Time !== ''))
                },
            });
        } catch (error) {
            console.log('Error : ', error);
        }
    }

    useEffect(() => {
        if (rangePresetsValue > 0) {
            setDateFromPickerSetValue(rangePresets[rangePresetsValue].value[0].startOf('day').unix())
            setDateToPickerSetValue(dayjs().endOf('day').unix())
        }
    }, [rangePresetsValue]);



    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: 'Ostatnie 7 dni', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: 'Ostatnie 30 dni', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: 'Ostatnie 60 dni', value: [dayjs().add(-60, 'd'), dayjs()] },
            { label: 'Cały zakres', value: [dayjs('2022-01-01'), dayjs()] },
        ];

    const onRangeChange = (dates: null | (Dayjs | null)[]) => {
        if (dates) {
            const from = dates[0]?.startOf('day').unix();
            const to = dates[1]?.endOf('day').unix();
            if (from && to) {
                setDateFromPickerSetValue(from);
                setDateToPickerSetValue(to);
            }
        } else {
            console.log('Clear');
        }
    };

    useEffect(() => {
        if (camera.length > 0) {
            setMinHorizontal(-1);
            setMaxHorizontal(1);
            setMinVertical(-1);
            setMaxVertical(1);

            camera.forEach((data: DESDataModel) => {

                if (minHorizontal > data.dxMm) {
                    setMinHorizontal(Math.round(data.dxMm) - 1);
                }
                if (data.dxMm > maxHorizontal) {
                    setMaxHorizontal(data.dxMm);
                }
                if (minVertical > data.dyMm) {
                    setMinVertical(Math.round(data.dyMm) - 1);
                }
                if (data.dyMm > maxVertical) {
                    setMaxVertical(data.dyMm);
                }
            });
        }
    }, [camera]);

    return (
        <div className="GraphsContainer"><div>
            {rangePresetsValue > 0 &&
                <RangePicker onChange={onRangeChange} presets={rangePresets} defaultValue={[rangePresets[rangePresetsValue].value[0].startOf('day'), dayjs()]} />
            }
        </div>
            {currendDevice && <div>
                <h1>{currendDevice.name}</h1>
                {currendDevice.dyMm &&
                    <>
                        <h2>Kamera przemieszczenie pionowe</h2>
                        <ResponsiveContainer width="95%" height={350}>
                            <LineChart
                                data={camera}
                                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                            >
                                <XAxis dataKey="DateTimeOriginal" />
                                <YAxis type="number" domain={[Math.round(minVertical) - 1, Math.round(maxVertical) + 1]} allowDataOverflow={false} />
                                <Tooltip />
                                <CartesianGrid stroke="#f5f5f5" />
                                <Line type="monotone" dataKey="dyMm" stroke="#000dff" yAxisId={0} />
                                <Brush />
                            </LineChart>
                        </ResponsiveContainer>
                    </>
                }
                {currendDevice.dxMm &&
                    <>
                        <h2>Kamera przemieszczenie poziome</h2>
                        <ResponsiveContainer width="95%" height={350}>
                            <LineChart
                                data={camera}
                                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                            >
                                <XAxis dataKey="DateTimeOriginal" />
                                <YAxis type="number" domain={[Math.round(minHorizontal) - 1, Math.round(maxHorizontal) + 1]} allowDataOverflow={false} />
                                <Tooltip />
                                <CartesianGrid stroke="#f5f5f5" />
                                <Line type="monotone" dataKey="dxMm" stroke="#ff0000" yAxisId={0} />
                                <Brush />
                            </LineChart>
                        </ResponsiveContainer>
                    </>
                }
                {currendDevice.cameraAI &&
                    <>
                        <h2>Kamera przemieszczenie poziome</h2>
                        <ResponsiveContainer width="95%" height={350}>
                            <ComposedChart data={mergeData} margin={{ top: 5, right: 20, left: 10, bottom: 5 }} >
                                <XAxis dataKey="dateTimeOriginal" />
                                <YAxis type="number" domain={['auto', 'auto']} allowDataOverflow={true} />

                                <Tooltip />
                                <CartesianGrid stroke="#f5f5f5" />
                                <Line type="monotone" dataKey="dyMm" name="ugięcie" stroke="#000dff" dot={null} />
                                <Scatter dataKey='category' name="zakres" fill="#8884d8" />
                                <Scatter dataKey='count' name="liczba" fill="#8884d8" />
                                <Brush />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </>
                }
                {currendDevice.weatherStation &&
                    <MeteorologicalData />
                }
            </div>
            }
        </div>
    )
}

export default Graphs